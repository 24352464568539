<template>
  <div class="main login-content">
    <a-form-model :model="loginInfo" :rules="loginRules" ref="loginForm">
      <a-form-model-item prop="username">
        <a-input size="large" placeholder="用户名/手机/邮箱" v-model="loginInfo.username">
          <a-icon slot="prefix" type="user"></a-icon>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="password">
        <a-input-password size="large" placeholder="密码" v-model="loginInfo.password">
          <a-icon slot="prefix" type="lock"></a-icon>
        </a-input-password>
      </a-form-model-item>
      <a-row>
        <a-col :span="16">
          <a-form-model-item prop="code">
            <a-input size="large" placeholder="验证码" v-model="loginInfo.code" @keypress.native.enter="onSubmitLogin">
              <a-icon slot="prefix" type="mail"></a-icon>
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <div style="border: 1px solid #dedede; text-align: center; background-color: #fff; border-left: 0">
            <img
              @click="changeCode"
              :src="codeSrc"
              title="看不清,换一张"
              alt="看不清,换一张"
              style="width: auto; height: 38px"
            />
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item prop="address">
            <a-select placeholder="请选择项目" v-model="loginInfo.address">
             <a-select-option :key="index" v-for="(item,index) in port" :value="item">{{item}}</a-select-option>
<!--            <a-select-option :value="'上海'">上海</a-select-option>-->
<!--            <a-select-option :value="'苏州'">苏州</a-select-option>-->
<!--            <a-select-option :value="'南京'">南京</a-select-option>-->
<!--            <a-select-option :value="'北京'">北京</a-select-option>-->
<!--            <a-select-option :value="'杭州'">杭州</a-select-option>-->
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item prop="type">
            <a-select placeholder="请选择后台端口" v-model="loginInfo.type">
              <a-select-option :value="'管理端'">管理端</a-select-option>
              <a-select-option :value="'事务所'">事务所</a-select-option>
              <a-select-option :value="'经销商'">经销商</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item>
        <a-button class="dl-btn" size="large" :loading="loading" type="primary" block @click="onSubmitLogin"
          >登录</a-button
        >
      </a-form-model-item>


    </a-form-model>
  </div>
</template>

<script>
const qs = require('qs')
const dealerUrl = process.env.VUE_APP_DEALER_URL
const firmUrl = process.env.VUE_APP_FIRM_URL

export default {
  data() {
    return {
      codeSrc: '',
      loading: false,
      loginInfo: {
        username: '',
        password: '',
        code: '',
      },
      port:process.env.VUE_APP_SYSTEM_PORT && process.env.VUE_APP_SYSTEM_PORT.split(','),
      serverCode: '',
      loginRules: {
        username: [
          {
            required: true,
            message: '请输入用户名/手机号/邮箱',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
          {
            len: 5,
            message: '验证码长度不正确',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (value != this.serverCode) {
                callback(new Error('输入验证码不正确'))
              }
              callback()
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.changeCode()
    this.$set(this.loginInfo,'address','上海')
    this.$set(this.loginInfo,'type','管理端')

    console.log(process.env.VUE_APP_SYSTEM_PORT)
  },
  methods: {
    /**
     * 刷新验证码
     */
    changeCode() {
      this.axios
        .get(`/api/base/auth/createCode`)
        .then((res) => {
          this.codeSrc = `data:image/png;base64,${res.body.verCodeImg}`
          this.serverCode = res.body.verCode
        })
        .catch((err) => {})
    },
    onSubmitLogin() {
      if (this.loading) {
        return false
      }
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          // this.$store
          //   .dispatch('Login', this.loginInfo)
          //   .then((res) => {
          //     this.$router.push('/dashboard/analysis')
          //   })
          //   .catch((err) => {})
          //   .finally(() => {
          //     this.loading = false
          //   })
          if (!this.loginInfo.type && !this.loginInfo.address) {
            this.loading = true
            this.$store
              .dispatch('Login', this.loginInfo)
              .then((res) => {
                this.$router.push('/dashboard/analysis')
              })
              .catch((err) => {})
              .finally(() => {
                this.loading = false
              })
          } else {
            if (this.loginInfo.address == '上海' && this.loginInfo.type == '管理端') {
              console.log("这里是登录方法");
              this.loading = true
              this.$store
                .dispatch('Login', this.loginInfo)
                .then((res) => {
                  this.$router.push('/dashboard/analysis')
                  //this.$store.dispatch('common/getInitBill')
                  // 登录后调起确认弹窗
                  this.$store.dispatch('common/getInitConfirm')
                  // 登录后吊起版本弹窗
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            } else if (this.loginInfo.address == '上海' && this.loginInfo.type == '经销商') {
              this.loading = true
              this.$store
                .dispatch('Login1', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  // window.location.href=`https://dealer.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                  window.location.href=`${dealerUrl}/dashboard/analysis?${qs.stringify(obj)}`
                  // window.location.href=`http://localhost:8001/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })

            } else if (this.loginInfo.address == '上海' && this.loginInfo.type == '事务所') {
               this.loading = true
              this.$store
                .dispatch('Login2', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  // window.location.href=`https://firm.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                  window.location.href=`${firmUrl}/dashboard/analysis?${qs.stringify(obj)}`
                  // window.location.href=`http://localhost:8001/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            } else if (this.loginInfo.address == '苏州' && this.loginInfo.type == '管理端') {
               this.loading = true
              this.$store
                .dispatch('Login3', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  window.location.href=`https://szcloud.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            } else if (this.loginInfo.address == '苏州' && this.loginInfo.type == '经销商') {
               this.loading = true
              this.$store
                .dispatch('Login4', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  window.location.href=`https://szdealer.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                  //  window.location.href=`http://localhost:8001/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            } else if (this.loginInfo.address == '苏州' && this.loginInfo.type == '事务所') {
               this.loading = true
              this.$store
                .dispatch('Login5', this.loginInfo)
                .then((res) => {
                  let obj = {
                   ...res.body,
                    code:1
                  }
                  window.location.href=`https://szfirm.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                  // window.location.href=`http://localhost:8000/dashboard/analysis?${qs.stringify(obj)}`

                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '南京' && this.loginInfo.type == '管理端') {
              console
               this.loading = true
              this.$store
                .dispatch('Login6', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  window.location.href=`https://njcloud.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '南京' && this.loginInfo.type == '经销商') {
               this.loading = true
              this.$store
                .dispatch('Login7', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  window.location.href=`https://njdealer.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '南京' && this.loginInfo.type == '事务所') {
               this.loading = true
              this.$store
                .dispatch('Login8', this.loginInfo)
                .then((res) => {
                  let obj = {
                   ...res.body,
                    code:1
                  }
                  window.location.href=`https://njfirm.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                  // window.location.href=`http://localhost:8000/dashboard/analysis?${qs.stringify(obj)}`

                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '北京' && this.loginInfo.type == '管理端') {
               this.loading = true
              this.$store
                .dispatch('Login9', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  window.location.href=`https://bjcloud.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '北京' && this.loginInfo.type == '经销商') {
               this.loading = true
              this.$store
                .dispatch('Login10', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  window.location.href=`https://bjdealer.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '北京' && this.loginInfo.type == '事务所') {
               this.loading = true
              this.$store
                .dispatch('Login11', this.loginInfo)
                .then((res) => {
                  let obj = {
                   ...res.body,
                    code:1
                  }
                  window.location.href=`https://bjfirm.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                  // window.location.href=`http://localhost:8000/dashboard/analysis?${qs.stringify(obj)}`

                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '杭州' && this.loginInfo.type == '管理端') {
              console
               this.loading = true
              this.$store
                .dispatch('Login12', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  window.location.href=`https://hzcloud.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '杭州' && this.loginInfo.type == '经销商') {
               this.loading = true
              this.$store
                .dispatch('Login13', this.loginInfo)
                .then((res) => {
                  let obj = {
                    ...res.body,
                    code:1
                  }
                  window.location.href=`https://hzdealer.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }else if (this.loginInfo.address == '杭州' && this.loginInfo.type == '事务所') {
               this.loading = true
              this.$store
                .dispatch('Login14', this.loginInfo)
                .then((res) => {
                  let obj = {
                   ...res.body,
                    code:1
                  }
                  window.location.href=`https://hzfirm.5uplus.com/dashboard/analysis?${qs.stringify(obj)}`
                  // window.location.href=`http://localhost:8000/dashboard/analysis?${qs.stringify(obj)}`

                })
                .catch((err) => {})
                .finally(() => {
                  this.loading = false
                })
            }
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.login-content {
  margin-left: 10% !important;
}

.dl-btn {
  background-color: #4963f3 !important;
  border-color: #4963f3 !important;
  color: white;
}
</style>
